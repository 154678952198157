
import "tailwindcss/tailwind.css"; 

const Hero = () => {
    return (
        <div className="bg-primary">
        <div class="relative isolate overflow-hidden">
  <div class="mt-[-50px] flex  items-center justify-between container md:flex-row flex-col max-md:pt-36 max-md:pb-24">
    <div class="max-w-full md:h-screen flex justify-between flex-shrink-0 px-4 text-center lg:mx-0 lg:max-w-3xl lg:pt-8">
      <div className="pt-20 flex flex-col items-start justify-center">
        {/* <div className="text-[#678497]">THE SOLUTION</div> */}
      <div class="mt-10 flex flex-col text-5xl font-extrabold tracking-tight text-white sm:text-5xl max-w-xl text-left gap-4">
      <span> AI-Powered Pricing </span> <span>Intelligence for</span> <span>Cybersecurity Solutions</span>    </div>

      <p className="text-[#d6d6d6] mt-8 text-xl text-left max-w-xl font-semibold opacity-80">
      Optimize your pricing strategy, maximize revenue, and stay ahead of the competition with Value Aligners' advanced AI technology.
      </p>

          <div className="mt-10 flex justify-between gap-7">

              <button className="px-6 md:px-12 py-4 bg-[#44677f] text-white font-semibold rounded-3xl flex items-center justify-center transition-all  hover:bg-white hover:text-black duration-300">Get a Demo</button>
              <button className="px-3 md:px-8 py-4 bg-transparent  border-4 border-[#44677f] text-white font-semibold rounded-3xl flex items-center justify-center transition-all  hover:bg-white hover:text-black hover:border-white duration-300">Our Solutions</button>

          </div>


      </div>






    </div>
    <div className="max-md:px-5">
      <img className="w-[500px] pt-36" src="/img/hero.svg"></img>
      
    </div>
  </div>
</div>

</div>
    )
}

export default Hero;