
import Content from '../comps/Content.js';


const AIPricingPlatform = () => {
    return (
        <div>

            <Content />

        </div>
    )
}


export default AIPricingPlatform;