import { Linkedin } from 'lucide-react';

import React, { useEffect, useState } from 'react';



const teamMembers = [
  {
    name: "Hamid S.",
    role: "Founder & CEO",
    description: "PhD in Information Systems with 10+ years in Project Management, Data Science and Digital Transformation",
    image: "/img/m5.JPG",
    lk: "https://www.linkedin.com/in/hamid-sha-phd-aa322a4a/"
  },
  {
    name: "Mahyar O.",
    role: "Head of AI",
    description: "PhD in Mathematical Modelling with 10+ years in Machine Learning",
    image: "/img/m4.JPG",
    lk: "https://www.linkedin.com/in/mahyar-osanlouy/"
  },
  {
    name: "Nicholas M.",
    role: "Head of Customer Success",
    description: "MBA with 10+ years in Customer Service, 6 years in Management",
    image: "/img/m2.PNG",
    lk: "https://www.linkedin.com/in/nmuffi/"
  },
  {
    name: "Abbas K.",
    role: "Pricing Advisor",
    description: "Master of Finance With 20+ Years in Pricing",
    image: "/img/m3.JPG",
    lk: "https://www.linkedin.com/in/abbas-koleini-cpp-99559231/"
  },
  {
    name: "Chris Hails",
    role: "Cybersecurity Advisor",
    description: "Master of Cybersecurity with 27+ years in Cybersecurity",
    image: "/img/m1.JPG",
    lk: "https://www.linkedin.com/in/chrishails/"
  },
  {
    name: "Mehdi S.",
    role: "Business Advisor",
    description: "PhD in Engineering with 17+ years in Business Development and Innovation",
    image: "/img/m6.JPG",
    lk: "https://www.linkedin.com/in/mehdish/"
  }
];


export default function TeamSection({st}) {
  const [stt, setstt] = useState();



  useEffect(() => {
      if(st == "dark") {
          setstt(true)
          console.log(stt)
  } else if (st == "light"){
      setstt(false)



  }
  }, [st]);  
  return (
    <section className="py-16 px-4 text-white mt-20 md:mt-40 flex flex-col items-center justify-center">
            <h2 className={` text-6xl max-w-[750px] mt-5  leading-normal ${ stt ? "text-white" : "text-gray-800"}`}>Our Team</h2>

      <div className="container mx-auto max-w-4xl mt-14 md:mt-32">

        <div className="grid md:grid-cols-2 gap-8">
          {teamMembers.map((member, index) => (
            <div style={{border: "solid 1px", borderColor: "rgb(38, 82, 112)"}}  key={index} className={`max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem]  transition-all ease-in-out ${ stt ? "text-white hover:bg-[#265270]" : "text-gray-800"} `}>
              <div className="flex items-center mb-4">
                <img
                  src={member.image}
                  alt={member.name}
                  width={100}
                  height={100}
                  className="rounded-3xl  mr-4"
                ></img>
                <div>
                  <h3 className="font-semibold text-lg text-left">{member.name}</h3>
                  <p className="text-gray-300 text-left">{member.role}</p>
                </div>
              </div>
              <p className={`mb-4 text-left ${ stt ? "text-gray-300 " : "text-gray-800"}`}>{member.description}</p>
              <div className="flex space-x-4">
                <a href={member.lk} className="text-gray-300 hover:text-[#4E7FA8]">
                <Linkedin />
                  <span className="sr-only">Linkedin</span>
                </a>
              </div>
            </div>
          ))}
        </div>

      </div>
    </section>
  );
}
