import React, { useEffect, useState } from 'react';

const Features = ({st}) => {

    const [stt, setstt] = useState();



    useEffect(() => {
        if(st == "dark") {
            setstt(true)
            console.log(stt)
    } else if (st == "light"){
        setstt(false)



    }
    }, [st]);

  return (
    <div className={`mt-[150px]  rounded-3xl ${ stt ? "!text-white" : ""} `}>
    <div className="flex md:flex-row flex-col-reverse justify-between container items-center max-md:gap-10">

        <div className="flex flex-col gap-3  rounded-3xl py-10 md:px-20 px-2 ">

            <div className="flex flex-col items-start justify-center">
                {/* <span className="text-primary font-semibold">SMART FEATURES</span> */}
                <span className={`text-black font-semibold text-2xl text-left ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Discover how Value Aligners can help you</span>
            </div>


            <div className="mt-7 flex flex-col gap-4">


                <div className=" flex gap-3 items-start text-left">
                    <span className={`font-extrabold text-4xl text-gray-700  py-2 px-4 shadow rounded-xl ${ stt ? "border border-gray-700 transition-all duration-700 text-white" : "bg-gray-100 transition-all duration-700"}`}>1.</span>
                    <div className="items-center pt-1 pl-2">
                        <h3 className={`text-gray-800 font-semibold text-xl text-left ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Intelligent Pricing Analysis</h3>
                        <div className="flex flex-col md:ml-3 mt-3 p-6 text-gray-500  shadow-sm rounded-3xl">
                            <ul className="list-disc md:pl-4">
                            <li className={`pb-3 ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Leverage AI to analyze market trends and competitor pricing</li>
                            <li className={` ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Gain actionable insights to optimize your pricing strategy</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className=" flex gap-3 items-start text-left">
                    <span className={`font-extrabold text-4xl text-gray-700  py-2 px-4 shadow rounded-xl ${ stt ? "border border-gray-700 transition-all duration-700 text-white" : "bg-gray-100 transition-all duration-700"}`}>2.</span> 
                    <div className="items-center pt-1 pl-2">
                        <h3 className={`text-gray-800 font-semibold text-xl text-left ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Dynamic Pricing Recommendations</h3>
                        <div className="flex flex-col md:ml-3 mt-3 p-6 text-gray-500  shadow-sm rounded-3xl">
                            <ul className="list-disc md:pl-4">
                            <li className={`pb-3 ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Receive real-time pricing suggestions based on market conditions</li>
                            <li className={` ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Adapt quickly to changes in demand and competitive landscape</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className=" flex gap-3 items-start text-left">
                    <span className="font-extrabold text-4xl text-gray-700 bg-gray-100 py-2 px-[12.5px] shadow rounded-xl">3.</span>
                    <div className="items-center pl-2 pt-1">
                        <h3 className={`text-gray-800 font-semibold text-xl text-left ${ stt ? "!text-white transition-all duration-700" : "transition-all duration-700"}`}>Competitor Monitoring</h3>
                        <div className="flex flex-col md:ml-3 mt-3 p-6 text-gray-500  shadow-sm rounded-3xl">
                            <ul className="list-disc md:pl-4">
                            <li className="pb-3">Track and analyze competitor pricing strategies</li>
                            <li className="">Stay informed about market positioning and pricing trends</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className=" flex gap-3 items-start text-left">
                    <span className="font-extrabold text-4xl text-gray-700 bg-gray-100 py-2 px-[12.1px] shadow rounded-xl">4.</span>
                    <div className="items-center pl-2 pt-1">
                        <h3 className="text-gray-800 font-semibold text-xl text-left">Industry Benchmarks</h3>
                        <div className="flex flex-col md:ml-3 mt-3 p-6 text-gray-500  shadow-sm rounded-3xl">
                            <ul className="list-disc md:pl-4">
                            <li className="pb-3">Compare your pricing against industry standards</li>
                            <li className="">Identify opportunities for improvement and growth</li>
                            </ul>
                        </div>
                    </div>
                </div>



            </div>



        </div>


        <div>
            <img className="w-[450px] max-md:px-5 max-md:w-[350px]" src="/img/fea.svg"></img>
        </div>

    </div>
    </div>
  );
};

 export default Features;