const CalltoAction = ({stt}) => {
    return (
        <div>
        {/* <div className="container border-t border-gray-300 mt-20">


<section class="text-gray-600 body-font relative">
    <div class="container px-5 py-24 mx-auto flex md:flex-row flex-col justify-center items-center  sm:flex-nowrap flex-wrap">

    <img className={`p-4 transition-all duration-700 md:w-[600px] ${stt ? "opacity-10" : ""}`} src="/img/cu.svg" alt="Illustration" />


        

        <div class="md:w-[800px] flex flex-col mb-10   md:py-8 mt-8 md:mt-0  bg-opacity-95  md:p-40">
            <h2 class="text-gray-900 text-3xl  font-medium title-font mb-4 text-left">Get a Demo</h2>

            <p className="text-left"> Ready to revolutionize your pricing strategy? Get started with Value Aligners today </p>



            <button class="text-white bg-primary mt-8 border-0 py-2 px-6 focus:outline-none hover:bg-blue-200 hover:text-gray-500 transition-all  rounded-2xl text-lg">Request a Demo</button>

         
        </div>
    </div>
</section>


        </div> */}

        <footer class=" mx-auto w-full relative text-center bg-primary text-white border-t border-gray-500">
    <div class="px-6 py-8 md:py-14 xl:pt-20 xl:pb-12 flex flex-col items-center">
    <img className={`p-4 transition-all duration-700 md:w-[600px] ${stt ? "opacity-10" : ""}`} src="/img/cu.svg" alt="Illustration" />

        <h2 class="font-bold text-3xl xl:text-4xl leading-snug">
        Ready to revolutionize your pricing strategy?<br></br>Get started with Value Aligners today!
        </h2>
        <a class="mt-8 xl:mt-12 px-12 py-5 text-lg font-medium leading-tight inline-block bg-[#265270] transition-all rounded-full shadow-xl border border-transparent hover:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-999 focus:ring-sky-500"
            href="#">Request a Demo</a>
        <div class="mt-14 xl:mt-20">
            <nav class="flex flex-col md:flex-row md:flex-wrap justify-center text-lg font-medium">
                <div class="px-5 py-2 flex flex-col justify-start text-left"><a href="#">
                Contact: <br></br> info@valuealigners.com
                </a></div>
                <div class="px-5 py-2 flex flex-col justify-start text-left"><a href="#">
                Phone: <br></br> +1 949-298-1946
                </a></div>
                <div class="px-5 py-2 flex flex-col justify-start text-left"><a href="#">
                Address: <br></br> 1027 Wilshire Blvd, Los Angeles, CA 90017, USA
                </a></div>

                <div class="px-5 py-2 flex items-end"><a href="https://www.linkedin.com/company/value-aligners/about/">Linkedin</a></div>
            </nav>
            <p class="mt-7 text-base ">© 2024 ValueAligners.ai</p>
        </div>
    </div>
</footer>

        
        </div>
        
    )
}

export default CalltoAction;