import Infrastructure from '../comps/Infrastructure.js';

const Ourplat = () => {
    return (
        <div className="pb-20">
            <Infrastructure />
        </div>
    )
}

export default Ourplat;