// import Header from './Header.js';
import Hero from './Hero.js';
import LogoCarousel from './LogoCarousel.js';
// import Content from './Content.js';
import React, { useEffect, useState } from 'react';
import Vision from './Vision.js';
import Problem from './Problem.js';
import Features from './Features.js';
import Howwork from './Howwork.js';
import Benefits from './Benefits.js';
import TeamSection from './TeamSection.js'; 
// import Content from './Content.js';
import CalltoAction from './CalltoAction.js';

// import Who from './Who.js';
import { isMobile } from 'react-device-detect';


const Index = () => {

  const [bgColor, setBgColor] = useState('bg-primary'); 
  const [bgst, setbgst] = useState("");


  const handleScroll = () => {
    const scrollY = window.scrollY;
    if(!isMobile) { 
      console.log("pc")
      if (scrollY < 500 ) {
        setBgColor('bg-primary');
        setbgst("dark");
      } else if (scrollY >= 500 && scrollY < 1300 ) {
        setBgColor('bg-white');
        setbgst("light");
      } else if (scrollY >= 1300 && scrollY < 2500 ) {
        setBgColor('bg-primary');
        setbgst("dark");
      } else if (scrollY >= 2500 && scrollY < 3475 ) {
        setBgColor('bg-white');
        setbgst("light");
      } else if (scrollY >= 3475 && scrollY < 5580 ) {
        setBgColor('bg-white');
        setbgst("light"); 
      }  else if (scrollY >= 5580 && scrollY < 6855 ) {
        setBgColor('bg-primary');
        setbgst("dark"); 
      } else if (scrollY >= 6855 && scrollY < 8060 ) {
        setBgColor('bg-white');
        setbgst("light"); 
      } 
             else {
        // setBgColor('bg-red-200');
        setBgColor('bg--white');
  
      }
    } else {

      console.log("mobile")

      if (scrollY < 950 ) {
        setBgColor('bg-primary');  
        setbgst("dark");
        
        }
       else if (scrollY >= 950 && scrollY < 2250 ) {
        setBgColor('bg-white');
        setbgst("light");
  
      } 
      else if (scrollY >= 2250 && scrollY < 4750 ) {
        setBgColor('bg-primary');
        setbgst("dark");
      } 
      else if (scrollY >= 4750 && scrollY < 9625 ) {
        setBgColor('bg-white');
        setbgst("light"); 
      } 
      else if (scrollY >= 9625 && scrollY < 11725 ) {
        setBgColor('bg-primary');  
        setbgst("dark");
      } 
      else if (scrollY >= 11725 && scrollY < 18855 ) {
        setBgColor('bg-white');
        setbgst("light"); 
      } 
          else {
        setBgColor('bg-white'); 
        // setBgColor('bg--white');
  
      }

    }
    
    //  inja be bad mobile


  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log(window.scrollY) 



  return (
    <div>
      {/* <Header /> */}
      <Hero />
      <LogoCarousel />
      <div>
        <div className={`relative min-h-screen flex flex-col transition-colors duration-700 ${bgColor}`}>
          <div className="pt-6  md:pt-10 ">
            <Vision st={bgst} />
            <Problem st={bgst} />
            <Features st={bgst} />
            <Howwork st={bgst} />     
            <Benefits st={bgst} />     
            <TeamSection st={bgst} />
            {/* <Content st={bgst} /> */}

            {/* <Who st={bgst} /> */}

            {/* <CalltoAction st={bgst} /> */}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
