import logo from './logo.svg';
import React from 'react';
import './App.css';
import AIPricingPlatform from './pages/AIPricingPlatform.js';
import Ourplat from './pages/Ourplat.js';
import Whoisitfor from './pages/Whoisitfor.js';
import Header from './comps/Header.js';
import CalltoAction from './comps/CalltoAction.js';
import Index from './comps/Index.js';
// import CardsHover from './comps/CardsHover.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* <CardsHover /> */}
      {/* <Footer /> */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/ai-pricing-platform" element={<AIPricingPlatform />} />
          <Route path="/ourplatform" element={<Ourplat />} />
          <Route path="/who-is-it-for" element={<Whoisitfor />} />
        </Routes>
      </Router>
      <CalltoAction />
    </div>
  );
}

export default App;
