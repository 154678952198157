import React, { useState, useEffect, useContext } from 'react';
// import '../styles.css';





const Content = ({st}) => {

    const [stt, setstt] = useState();
    console.log(st)





    useEffect(() => {
        if(st == "dark") {
            setstt(true)
            console.log(stt)
    } else if (st == "light"){
        setstt(false)



    }
    }, [st]);












    return (
        <div>









            <section className="">  
                <div className="flex flex-col items-center pt-36">
                {/* <div class="text-white bg-primary p-3 text-xs shadow  rounded-2xl mt-20 mb-4">Solutions and Features</div> */}
            <h2 className={` text-6xl  mb-10 max-w-[750px]  leading-normal ${ stt ? "text-white" : "text-gray-800"}`}>AI Pricing Platform</h2>
            <p className={` max-w-[750px] mb-10 ${ stt ? "text-white" : "text-gray-800"}`}>Value Aligners' AI Pricing Platform is a state-of-the-art solution designed specifically for the cybersecurity industry. Our platform leverages advanced machine learning algorithms and real-time market data to provide dynamic, industry-specific pricing recommendations.</p>



                <div className="flex flex-col w-full p-8 md:p-20 bgs-[#03050D] ">
                    <div className="flex justify-center  container">
                    <div className={`flex md:flex-row flex-col justify-between items-center gap-16 font-extrabold  ${
                  stt ? "text-white" : "text-gray-600"
                }`}>


                            <div className="md:w-[450px] pt-4 tracking-tight leading-8">
                                
                                <h3 className="text-4xl text-left">Intelligent Pricing Analysis</h3>
                                <div class="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 shadow-sm rounded-3xl items-start text-left pt-8"><ul class="list-disc">
                                    <li class="pb-3 transition-all duration-700">AI-driven analysis of market trends and competitor pricing</li>
                                    <li class="transition-all duration-700">Real-time pricing recommendations based on current market conditions</li>
                                    </ul></div>


                            </div>
                    <img className="w-[450px] rounded-[40px] " src="/img/c1.svg"></img>


                    </div>
                    </div>
                </div>
                </div>
            </section>






            <section className="bgs-[#03050D]">
                <div className="flex flex-col items-center">


                <div className="flex flex-col w-full p-8 md:p-20">
                    <div className="flex justify-center  container">
                    <div className="flex md:flex-row flex-col-reverse justify-between items-center gap-16 font-extrabold text-gray-600">
                    <img className="w-[450px] rounded-[40px] " src="/img/c2.svg"></img>


                            <div className="md:w-[450px] pt-4 tracking-tight leading-8">
                                
                                <h3 className="text-4xl text-left">Industry Benchmarks</h3>
                                <div class="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 shadow-sm rounded-3xl items-start text-left pt-8"><ul class="list-disc">
                                    <li class="pb-3 transition-all duration-700">Comprehensive benchmarking against top industry players</li>
                                    <li class="transition-all duration-700">Customizable benchmark suggestions</li>
                                    </ul></div>
                            </div>

                    </div>
                    </div>
                </div>
                </div>
            </section>


            {/* 2 ta  */}


            <section className="">
                <div className="flex flex-col items-center">




                <div className="flex flex-col w-full p-8 md:p-20 bgs-[#03050D] ">
                    <div className="flex justify-center  container">
                    <div className={`flex md:flex-row flex-col justify-between items-center gap-16 font-extrabold  ${
                  stt ? "text-white" : "text-gray-600"
                }`}>


                            <div className="md:w-[450px] pt-4 tracking-tight leading-8">
                                
                                <h3 className="text-4xl text-left">Competitive Product Scraping and Matching</h3>
                                <div class="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 shadow-sm rounded-3xl items-start text-left pt-8"><ul class="list-disc">
                                    <li class="pb-3 transition-all duration-700">Automated tracking of competitor products and pricing</li>
                                    <li class="transition-all duration-700">Intelligent matching of products for accurate comparisons</li>
                                    </ul></div>


                            </div>
                    <img className="w-[450px] rounded-[40px] " src="/img/c3.svg"></img>


                    </div>
                    </div>
                </div>
                </div>
            </section>






            <section className="bgs-[#03050D]">
                <div className="flex flex-col items-center">


                <div className="flex flex-col w-full p-8 md:p-20">
                    <div className="flex justify-center  container">
                    <div className="flex md:flex-row flex-col-reverse justify-between items-center gap-16 font-extrabold text-gray-600">
                    <img className="w-[450px] rounded-[40px] " src="/img/c4.svg"></img>


                            <div className="md:w-[450px] pt-4 tracking-tight leading-8">
                                
                                <h3 className="text-4xl text-left">Advanced AI Models</h3>
                                <div class="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 shadow-sm rounded-3xl items-start text-left pt-8"><ul class="list-disc">
                                    <li class="pb-3 transition-all duration-700">Custom AI models tailored to the cybersecurity industry</li>
                                    <li class="transition-all duration-700">Multi-methodology integration for holistic pricing strategies</li>
                                    </ul></div>
                            </div>

                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="bgs-[#03050D]">
                <div className="flex flex-col items-center">


                <div className="flex flex-col w-full p-8 md:p-20">
                    <div className="flex justify-center  container">
                    <div className="flex md:flex-row flex-col justify-between items-center gap-16 font-extrabold text-gray-600">


                            <div className="md:w-[450px] pt-4 tracking-tight leading-8">
                                
                                <h3 className="text-4xl text-left">Data Visualization and Insights</h3>
                                <div class="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 shadow-sm rounded-3xl items-start text-left pt-8"><ul class="list-disc">
                                    <li class="pb-3 transition-all duration-700">Intuitive dashboards for easy interpretation of pricing data</li>
                                    <li class="transition-all duration-700">Actionable insights to inform pricing decisions</li>
                                    </ul></div>
                            </div>
                    <img className="w-[450px] rounded-[40px] " src="/img/c5.svg"></img>


                    </div>
                    </div>
                </div>
                </div>
            </section>


            {/* 3 ta  */}



            






        </div>
    )
}

export default Content;