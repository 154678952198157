const Infrastructure = () => {
    var stt = false;
    return(
        <section className="pt-36">
        <div className="flex flex-col items-center">
             {/* <div class="text-white bg-primary p-3 text-xs shadow  rounded-2xl mt-20 mb-4">Our platform</div> */}
             <h2 className={` text-6xl  mb-10 max-w-[750px]  leading-normal ${ stt ? "text-white" : "text-gray-800"}`}>Infrastructure</h2>
    <p className=" max-w-[750px] mb-10">Our platform is built on a robust, scalable cloud infrastructure, ensuring high performance, security, and reliability:</p>
    <div className="flex justify-between items-center container flex-col-reverse md:flex-row-reverse ">

<div>
<img className="w-[500px]" src="/img/ser.svg"></img>
</div>

<div className="flex flex-col items-start ">

{/* start  */}

<div className=" flex flex-row px-10 py-8 rounded-3xl items-center gap-10 justify-center">
<div >

    <svg className=" shadow-xl rounded-2xl p-3" width="52px"  height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 19C4.01472 19 2 16.9853 2 14.5C2 12.1564 3.79151 10.2313 6.07974 10.0194C6.54781 7.17213 9.02024 5 12 5C14.9798 5 17.4522 7.17213 17.9203 10.0194C20.2085 10.2313 22 12.1564 22 14.5C22 16.9853 19.9853 19 17.5 19C13.1102 19 10.3433 19 6.5 19Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className=" text-gray-800 text-lg text-left">Cloud-based deployment for easy access and scalability</div>
</div>

{/* end  */}

{/* start  */}

<div className=" flex flex-row px-10 py-8 rounded-3xl items-center gap-10 justify-center">
<div >

    <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5708 20C19.8328 20 20.8568 18.977 20.8568 17.714V13.143L21.9998 12L20.8568 10.857V6.286C20.8568 5.023 19.8338 4 18.5708 4M5.429 4C4.166 4 3.143 5.023 3.143 6.286V10.857L2 12L3.143 13.143V17.714C3.143 18.977 4.166 20 5.429 20M7.5 12L9.93431 14.4343C10.1323 14.6323 10.2313 14.7313 10.3455 14.7684C10.4459 14.8011 10.5541 14.8011 10.6545 14.7684C10.7687 14.7313 10.8677 14.6323 11.0657 14.4343L16.5 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className=" text-gray-800 text-lg text-left">Advanced data encryption and security protocols</div>
</div>

{/* end  */}

{/* start  */}

<div className=" flex flex-row px-10 py-8 rounded-3xl items-center gap-10 justify-center">
<div >

    <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2.25V4.75M12 18V22M5.75 12H2.25M21.25 12H19.75M18.4571 18.4571L17.75 17.75M18.6642 5.41579L17.25 6.83M4.92157 19.0784L7.75 16.25M5.12868 5.20868L7.25 7.33" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className=" text-gray-800 text-lg text-left">Regular updates and maintenance for optimal performance</div>
</div>

{/* end  */}

{/* start  */}

<div className=" flex flex-row px-10 py-8 rounded-3xl items-center gap-10 justify-center">
<div >

    <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 17L22 12L17 7M7 7L2 12L7 17M14 3L10 21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className=" text-gray-800 text-lg text-left">API integration capabilities for seamless incorporation into existing systems</div>
</div>

{/* end  */}


</div>

</div>





        </div>
    </section >
    )
}

export default Infrastructure;