import { Linkedin } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Firstwho from './Firstwho.js';



export default function Who({ st }) {

  const caseStudies = [
    {
      title: "Pricing Managers",
      description: "Value Aligners helps pricing managers optimize strategies and reduce the need for large teams through:",
      item1: "Data-driven insights for dynamic pricing adjustments",
      item2: "Automated competitor analysis and market trend tracking",
      item3: "Customizable pricing models based on market segments",
      titleSub: "Optimizing Pricing Strategy for CyberShield Inc.",
      descriptionSub: "CyberShield Inc., a mid-sized cybersecurity firm, struggled with pricing inconsistencies across different markets. After implementing Value Aligners:",
      item1Sub: "Pricing optimization led to a 15% increase in overall revenue",
      item2Sub: "Reduced pricing team size by 30% while improving efficiency",
      item3Sub: "Achieved 98% accuracy in competitor price tracking, enabling real-time adjustments",
      img: "/img/pr.svg",
    },
    {
      title: "Product Managers",
      description: "Product managers gain crucial insights for development and positioning:",
      item1: "Feature prioritization based on market demand and pricing data",
      item2: "Competitive analysis for product differentiation",
      item3: "Price elasticity studies for new product launches",
      titleSub: "Market-Driven Product Development at SecureNet Solutions",
      descriptionSub: "SecureNet Solutions used Value Aligners to guide their product strategy:",
      item1Sub: "Identified an underserved market segment, leading to a new product line with $5M in first-year revenue",
      item2Sub: "Optimized feature set based on pricing data, increasing customer satisfaction by 25%",
      item3Sub: "Reduced time-to-market for new products by 20% through data-driven decision making",
      img: "/img/ba.svg",

    },
    {
      title: "Sales Directors",
      description: "Sales teams benefit from:",
      item1: "Real-time competitive pricing information for negotiations",
      item2: "Customized pricing recommendations for different customer segments",
      item3: "Historical pricing trends to forecast future market movements",
      titleSub: "Empowering Sales at CyberGuard Technologies",
      descriptionSub: "CyberGuard Technologies leveraged Value Aligners to enhance their sales processes:",
      item1Sub: "Increased win rates by 30% with access to real-time competitive pricing",
      item2Sub: "Improved average deal size by 20% through data-driven upselling",
      item3Sub: "Reduced sales cycle length by 15% with more accurate initial pricing proposals",
      img: "/img/dr.svg",

    },
    {
      title: "C-Suite Executives (CEOs, CTOs, CFOs)",
      description: "Executives gain strategic insights for informed decision-making:",
      item1: "Comprehensive market overviews for long-term planning",
      item2: "Profitability analysis across product lines and market segments",
      item3: "Benchmarking against industry leaders and emerging competitors",
      titleSub: "Strategic Repositioning at FortressData Systems",
      descriptionSub: "FortressData Systems used Value Aligners to guide a major strategic shift:",
      item1Sub: "Identified a high-growth market segment, leading to a successful pivot that increased market share by 10%",
      item2Sub: "Optimized resource allocation, improving overall profitability by 18%",
      item3Sub: "Guided a strategic acquisition based on pricing and market data, expanding service offerings",
      img: "/img/ceo.svg",

    },
    {
      title: "Marketing Directors",
      description: "Marketing teams can develop more targeted campaigns through:",
      item1: "Pricing insights for promotional strategies",
      item2: "Competitor positioning analysis",
      item3: "Customer segmentation based on pricing preferences",
      titleSub: "Data-Driven Marketing at CyberSafe Solutions",
      descriptionSub: "CyberSafe Solutions transformed their marketing approach with Value Aligners:",
      item1Sub: "Increased marketing ROI by 40% through targeted campaigns based on pricing insights",
      item2Sub: "Improved brand positioning, leading to a 25% increase in inbound leads",
      item3Sub: "Developed a successful freemium model, increasing user base by 150% in 6 months",
      img: "/img/mr.svg",

    },
    {
      title: "Business Development Managers",
      description: "Business development benefits from:",
      item1: "Identification of new market opportunities based on pricing trends",
      item2: "Competitive intelligence for partnership and acquisition strategies",
      item3: "Data-backed proposals for new business pitches",
      titleSub: "Expanding Market Reach at SecureLogic Inc.",
      descriptionSub: "SecureLogic Inc. used Value Aligners to drive business development:",
      item1Sub: "Identified and successfully entered two new geographical markets, increasing revenue by 30%",
      item2Sub: "Developed a tiered pricing strategy for channel partners, growing partner network by 50%",
      item3Sub: "Improved proposal win rates by 35% with data-backed pricing and market analysis",
      img: "/img/de.svg",

    }
  ];
  

  const [stt, setstt] = useState();

  useEffect(() => {
    if (st === "dark") {
      setstt(true);
      console.log(stt);
    } else if (st === "light") {
      setstt(false);
      console.log(stt);
    }
  }, [st]);






  return (
    <section className="py-16 px-4 pt-36  flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <h2 className={`text-6xl max-w-[750px] mt-5 leading-normal ${stt ? "text-white" : "text-gray-800"}`}>
          Who is it for?
        </h2>
        <img className={`p-4 transition-all duration-700 ${stt ? "opacity-10" : ""}`} src="/img/th.svg" alt="Illustration" />
      </div>

      <div className="container mx-auto max-w-4xl mt-14 md:mt-20 border-t">
        <div className="grid md:grid-cols-1 gap-8 pt-10">
          {caseStudies.map((cases, index) => (
          
            <Firstwho index={index} id={index} cases={cases} />
            
          ))}


        </div>
      </div>
    </section>
  );
}
