import React, { useEffect, useState } from 'react';

const Problem = ({st}) => {
    const [stt, setstt] = useState();



    useEffect(() => {
        if(st == "dark") {
            setstt(true)
            console.log(stt)
    } else if (st == "light"){
        setstt(false)



    }
    }, [st]);
    return (
        <div className="flex flex-col items-center justify-center mt-[280px] ">


            {/* <div className="text-white bg-primary p-3 text-xs shadow  rounded-2xl">Problem Statement</div> */}

            <h2 className={` text-6xl max-w-[750px] mt-5 leading-normal ${ stt ? "text-white" : "text-[#15364d]"}`}>Empower your business with intelligent AI solutions</h2>

            <p className="max-w-[550px] text-gray-400 font-medium  rounded-3xl p-[2rem] transition-all ease-in-out">In the rapidly evolving cybersecurity market, solution providers face the challenge of accurately pricing their offerings amidst </p>


            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 max-md:px-4">

                {/* start box  */}
                    <div style={{border: "solid 1px", borderColor: "#265270"}} className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#265270] transition-all ease-in-out flex flex-col items-start justify-center">
                        <div className="bg-[#15364d] p-3 rounded-xl">
                                <svg className="text-white" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M15.5 3.5V2M19.4393 4.56066L20.5 3.5M20.5103 8.5H22.0103M21.9506 13C21.4489 18.0533 17.1853 22 12 22C6.47715 22 2 17.5228 2 12C2 6.81465 5.94668 2.5511 11 2.04938M12 8H16V12M15.6197 8C13.2653 11.3276 9.38636 13.5 5 13.5C3.9971 13.5 3.02072 13.3864 2.08302 13.1715" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                        </div>

                        <div className="mt-5">
                            <h3 className="text-white text-xl font-semibold text-left pb-2">Market Complexity</h3>
                            <p className="text-left text-gray-400">The cybersecurity market is highly fragmented and rapidly evolving, making it difficult for providers to accurately price their offerings.</p>
                        </div>
                    </div>

                    {/* end box  */}

                                    {/* start box  */}
                                    <div style={{border: "solid 1px", borderColor: "#265270"}} className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#265270] transition-all ease-in-out flex flex-col items-start justify-center">
                        <div className="bg-[#15364d] p-3 rounded-xl">
                            <svg className="text-white" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.41345 10.7445C2.81811 10.513 2.52043 10.3972 2.43353 10.2304C2.35819 10.0858 2.35809 9.91354 2.43326 9.76886C2.51997 9.60195 2.8175 9.48584 3.41258 9.25361L20.3003 2.66327C20.8375 2.45364 21.1061 2.34883 21.2777 2.40616C21.4268 2.45596 21.5437 2.57292 21.5935 2.72197C21.6509 2.8936 21.5461 3.16219 21.3364 3.69937L14.7461 20.5871C14.5139 21.1822 14.3977 21.4797 14.2308 21.5664C14.0862 21.6416 13.9139 21.6415 13.7693 21.5662C13.6025 21.4793 13.4867 21.1816 13.2552 20.5862L10.6271 13.8282C10.5801 13.7074 10.5566 13.647 10.5203 13.5961C10.4881 13.551 10.4487 13.5115 10.4036 13.4794C10.3527 13.4431 10.2923 13.4196 10.1715 13.3726L3.41345 10.7445Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="mt-5">
                            <h3 className="text-white text-xl font-semibold text-left pb-2">Competitive Pressure</h3>
                            <p className="text-left text-gray-400">With over 9,800 cybersecurity companies in the U.S. alone, competition is intense, necessitating sophisticated pricing strategies.</p>
                        </div>
                    </div>

                    {/* end box  */}

                                    {/* start box  */}
                                    <div style={{border: "solid 1px", borderColor: "#265270"}} className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#265270] transition-all ease-in-out flex flex-col items-start justify-center">
                        <div className="bg-[#15364d] p-3 rounded-xl">
                            <svg className="text-white" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M21 7L15.5657 12.4343C15.3677 12.6323 15.2687 12.7313 15.1545 12.7684C15.0541 12.8011 14.9459 12.8011 14.8455 12.7684C14.7313 12.7313 14.6323 12.6323 14.4343 12.4343L12.5657 10.5657C12.3677 10.3677 12.2687 10.2687 12.1545 10.2316C12.0541 10.1989 11.9459 10.1989 11.8455 10.2316C11.7313 10.2687 11.6323 10.3677 11.4343 10.5657L7 15M21 7H17M21 7V11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="mt-5">
                            <h3 className="text-white text-xl font-semibold text-left pb-2">Financial Impact</h3>
                            <p className="text-left text-gray-400">The average U.S. firm loses $1.85 million annually from cybersecurity attacks, highlighting the critical need for effective, well-priced security solutions.</p>
                        </div>
                    </div>

                    {/* end box  */}

                                    {/* start box  */}
                                    <div style={{border: "solid 1px", borderColor: "#265270"}} className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#265270] transition-all ease-in-out flex flex-col items-start justify-center">
                        <div className="bg-[#15364d] p-3 rounded-xl">
                            <svg className="text-white" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 12H4.6C4.03995 12 3.75992 12 3.54601 12.109C3.35785 12.2049 3.20487 12.3578 3.10899 12.546C3 12.7599 3 13.0399 3 13.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H9M9 21H15M9 21L9 8.6C9 8.03995 9 7.75992 9.10899 7.54601C9.20487 7.35785 9.35785 7.20487 9.54601 7.10899C9.75992 7 10.0399 7 10.6 7H13.4C13.9601 7 14.2401 7 14.454 7.10899C14.6422 7.20487 14.7951 7.35785 14.891 7.54601C15 7.75992 15 8.03995 15 8.6V21M15 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V4.6C21 4.03995 21 3.75992 20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3H16.6C16.0399 3 15.7599 3 15.546 3.10899C15.3578 3.20487 15.2049 3.35785 15.109 3.54601C15 3.75992 15 4.03995 15 4.6V8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="mt-5">
                            <h3 className="text-white text-xl font-semibold text-left pb-2">Market Growth</h3>
                            <p className="text-left text-gray-400">The cybersecurity market is projected to reach USD 376.55 billion by 2029, with a CAGR of 12.63%. This rapid growth demands dynamic pricing strategies.</p>
                        </div>
                    </div>

                    {/* end box  */}

                    {/* start box  */}
                    <div style={{border: "solid 1px", borderColor: "#265270"}} className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#265270] transition-all ease-in-out flex flex-col items-start justify-center">
                        <div className="bg-[#15364d] p-3 rounded-xl">
                            <svg className="text-white" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 20V13M12 20V10M4 20L4 16M13.4067 5.0275L18.5751 6.96567M10.7988 5.40092L5.20023 9.59983M21.0607 6.43934C21.6464 7.02513 21.6464 7.97487 21.0607 8.56066C20.4749 9.14645 19.5251 9.14645 18.9393 8.56066C18.3536 7.97487 18.3536 7.02513 18.9393 6.43934C19.5251 5.85355 20.4749 5.85355 21.0607 6.43934ZM5.06066 9.43934C5.64645 10.0251 5.64645 10.9749 5.06066 11.5607C4.47487 12.1464 3.52513 12.1464 2.93934 11.5607C2.35355 10.9749 2.35355 10.0251 2.93934 9.43934C3.52513 8.85355 4.47487 8.85355 5.06066 9.43934ZM13.0607 3.43934C13.6464 4.02513 13.6464 4.97487 13.0607 5.56066C12.4749 6.14645 11.5251 6.14645 10.9393 5.56066C10.3536 4.97487 10.3536 4.02513 10.9393 3.43934C11.5251 2.85355 12.4749 2.85355 13.0607 3.43934Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="mt-5">
                            <h3 className="text-white text-xl font-semibold text-left pb-2">Data-Driven Decision Making</h3>
                            <p className="text-left text-gray-400">TThere's a growing need for AI-driven tools that can process vast amounts of market data to inform pricing decisions.</p>
                        </div>
                    </div>

                    {/* end box  */}

                    {/* des  */}
                    <div className="max-w-[550px] text-gray-400 font-medium  rounded-3xl p-[2rem] leading-loose flex flex-col items-start justify-center">
                        <div className="">
                            <p className="text-left text-gray-300">Value Aligners addresses this gap by providing an AI-driven pricing platform specifically tailored to the cybersecurity industry's unique challenges and opportunities.</p>
                        </div>
                    </div>
                    {/* des  */}

            </div>



        </div>
    )
}

export default Problem;