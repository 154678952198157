import React, { useState, useEffect } from 'react';
import '../App.css'; // مطمئن شوید که فایل CSS را اضافه کرده‌اید یا استایل‌ها را مستقیماً در کامپوننت اضافه کنید

const LogoCarousel = () => {
  const [bannerOpen, setBannerOpen] = useState(true);

  useEffect(() => {
    const ul = document.querySelector('.logo-carousel ul');
    if (ul) {
      const clone = ul.cloneNode(true);
      clone.setAttribute('aria-hidden', 'true');
      ul.parentNode.insertBefore(clone, ul.nextSibling);
    }
  }, []);

  return (
    <div className="relative font-inter antialiased ">
      <main className="relative  flex flex-col justify-center bg-primary py-20 overflow-hidden">
        <div className="w-full max-w-5xl mx-auto px-4 md:px-6 md:py-5">
          <div className="text-center hidden">
            {/* Logo Carousel animation */}
            <div
              className="logo-carousel  w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
            >
              <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll ">
                <li><img className="w-[120px]" src="/img/l1.svg" alt="Facebook" /></li>
                <li><img className="w-[120px]" src="/img/l2.svg" alt="Disney" /></li>
                <li><img className="w-[120px]" src="/img/l3.svg" alt="Airbnb" /></li>
                <li><img className="w-[120px]" src="/img/l4.svg" alt="Apple" /></li>
                <li><img className="w-[120px]" src="/img/l5.webp" alt="Spark" /></li> 
                <li><img className="w-[120px]" src="/img/l3.svg" alt="Airbnb" /></li>
                <li><img className="w-[120px]" src="/img/l4.svg" alt="Apple" /></li>
                <li><img className="w-[120px]" src="/img/l5.webp" alt="Spark" /></li>
              </ul>
            </div>
            {/* End: Logo Carousel animation */}
          </div>
        </div>
      </main>
      

    </div>
  );
};

export default LogoCarousel;
