import Who from '../comps/Who.js';

const Whoisitfor = () => {
    return (
        <div>
            <Who />
        </div>
    )
}

export default Whoisitfor;