import React, { useState, useEffect, useContext } from "react";
import {Link, useNavigate} from "react-router-dom";




const Header = ({}) => {
  const navigate = useNavigate();


  const handleClick1 = () => {
    navigate('/ai-pricing-platform');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  const handleClick2 = () => {
    navigate('/ourplatform');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  const handleClick3 = () => {
    navigate('/who-is-it-for');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };



    const [atTop, setAtTop] = useState(true);
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        setAtTop(window.pageYOffset <= 50);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);


    return (
        <header>
        <div
          className={`fixed z-50 w-full px-8 py-4 transition-all duration-1000 rounded-3xl  md:max-w-2xl  inset-x-0 mx-auto ease-in-out transform ${
            atTop ? "md:max-w-3xl max-w-[350px] bg-primary mt-3 shadow-2xl" : "bg-[#6DB7F2] bg-opacity-60 backdrop-blur-xl md:max-w-7xl max-w-[390px] mt-5"
          }`}
        >
          <div className="flex flex-col w-full p-2 mx-auto md:items-center md:justify-between md:flex-row">
            <div className="flex flex-row items-center justify-between">
              <a href="/"
                className={`font-bold tracking-tighter text-white uppercase ${
                  atTop ? "text-white" : "text-white"
                }`}
              >
                <img src="/img/logov.png" className="w-[80px]"></img>
              </a>
              <button className="md:hidden focus:outline-none" onClick={() => setOpen(!open)}>
              <svg className="text-white" width="25px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M3 12H21M3 6H21M3 18H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
              </button>
            </div>
            <nav
              className={`max-md:pt-10 flex-col flex-grow gap-8 pb-4 md:pb-0 md:flex md:flex-row lg:ml-auto md:items-center justify-end ${
                open ? "flex" : "hidden"
              }`}
            >
              <a className={atTop ? "text-white cursor-pointer" : "text-white cursor-pointer"} onClick={handleClick1}>
              AI Pricing Platform
              </a>
              <a className={atTop ? "text-white cursor-pointer" : "text-white cursor-pointer"} onClick={handleClick2}>
              Our platform
              </a>
              <a className={atTop ? "text-white cursor-pointer" : "text-white cursor-pointer"} onClick={handleClick3}>
              Who is it for
              </a>
            </nav>
          </div>
        </div>

      </header>
    )
}

export default Header;