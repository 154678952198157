import React, { useEffect, useState } from 'react';

const Howwork = ({st}) => {
    const [stt, setstt] = useState();



    useEffect(() => {
        if(st == "dark") {
            setstt(true)
            console.log(stt)
    } else if (st == "light"){
        setstt(false)



    }
    }, [st]);
    return (
        <div className="flex flex-col items-center justify-center mt-[280px] ">


            <h2 className={` text-6xl max-w-[750px] mt-5  leading-normal ${ stt ? "text-white" : "text-gray-800"}`}>How It Works</h2>

            <div className="grid grid-cols-4">

                

            </div>

            {/* <img className="w-[300px] mt-10" src="/img/processing.svg"></img> */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 max-md:px-4">

{/* start box  */}
    <div  className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#f5f5f5] transition-all ease-in-out flex flex-col items-start justify-center shadow">
        <div className="shadow-xl    p-3 px-5 rounded-xl text-black text-2xl">
            1
        </div>

        <div className="mt-5">
            <h3 className="text-gray-700 text-xl font-semibold text-left pb-2">Connect Your Data</h3>
            <p className="text-left text-gray-500">Seamlessly integrate your pricing and sales data with our secure platform</p>
        </div>
    </div>

    {/* end box  */}

{/* start box  */}
<div  className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#f5f5f5] transition-all ease-in-out flex flex-col items-start justify-center shadow">
        <div className="shadow-xl   p-3 px-5 rounded-xl text-black text-2xl ">
            2
        </div>

        <div className="mt-5">
            <h3 className="text-gray-700 text-xl font-semibold text-left pb-2">AI-Powered Analysis</h3>
            <p className="text-left text-gray-500">Our advanced algorithms analyze your data and market trends</p>
        </div>
    </div>

    {/* end box  */}

    {/* start box  */}
<div  className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#f5f5f5] transition-all ease-in-out flex flex-col items-start justify-center shadow">
        <div className="shadow-xl   p-3 px-5 rounded-xl text-black text-2xl ">
            3
        </div>

        <div className="mt-5">
            <h3 className="text-gray-700 text-xl font-semibold text-left pb-2">Receive Actionable Insights</h3>
            <p className="text-left text-gray-500">Get clear, data-driven recommendations to optimize your pricing strategy</p>
        </div>
    </div>

    {/* end box  */}

        {/* start box  */}
<div  className="max-w-[550px] text-gray-500 font-medium  rounded-3xl p-[2rem] hover:bg-[#f5f5f5] transition-all ease-in-out flex flex-col items-start justify-center shadow">
        <div className="shadow-xl   p-3 px-5 rounded-xl text-black text-2xl ">
            4
        </div>

        <div className="mt-5">
            <h3 className="text-gray-700 text-xl font-semibold text-left pb-2">Implement and Monitor</h3>
            <p className="text-left text-gray-500">Easily implement changes and track performance in real-time</p>
        </div>
    </div>

    {/* end box  */}


</div>


            



        </div>
    )
}

export default Howwork;