import React, { useEffect, useState } from 'react';

const Vision = ({st}) => {
    const [stt, setstt] = useState();



    useEffect(() => {
        if(st == "dark") {
            setstt(true)
            console.log(stt)
    } else if (st == "light"){
        setstt(false)



    }
    }, [st]);
    return (
        <div className=" flex justify-center items-center pt-20">
            <div className="flex justify-between flex-col-reverse  md:flex-row-reverse items-center gap-32 max-md:px-4">
                <div className="flex flex-col gap-4">


                    {/* start item  */}


                    <div className="flex justify-between items-start shadow p-6 rounded-3xl">
                        <div className="bg-white shadow-lg p-2 rounded-xl">
                            <svg className="text-primary" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 17V19.4C18 19.9601 18 20.2401 17.891 20.454C17.7951 20.6422 17.6422 20.7951 17.454 20.891C17.2401 21 16.9601 21 16.4 21H7.6C7.03995 21 6.75992 21 6.54601 20.891C6.35785 20.7951 6.20487 20.6422 6.10899 20.454C6 20.2401 6 19.9601 6 19.4V17M6.8 17H17.2C18.8802 17 19.7202 17 20.362 16.673C20.9265 16.3854 21.3854 15.9265 21.673 15.362C22 14.7202 22 13.8802 22 12.2V7.8C22 6.11984 22 5.27976 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V12.2C2 13.8802 2 14.7202 2.32698 15.362C2.6146 15.9265 3.07354 16.3854 3.63803 16.673C4.27976 17 5.11984 17 6.8 17Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="pl-3 max-w-[510px] ">
                            <h3 className={` text-left font-semibold  ${ stt ? "text-white" : "text-gray-800"}`}>Vision</h3>
                            <p className={ `text-left opacity-70 font-normal ${ stt ? "text-white" : "text-gray-800"}`}>To revolutionize pricing strategies in the cybersecurity industry through advanced AI technology, enabling businesses to maximize profitability and maintain competitiveness in a rapidly evolving market.</p>
                        </div>

                    </div>

                    {/* end item  */}

                    {/* start item  */}


                                        <div className="flex justify-between items-start shadow p-6 rounded-3xl">
                                            <div className="bg-white shadow-lg p-2 rounded-xl">
                                                <svg className="text-primary" width="22px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 14.9998L9 11.9998M12 14.9998C13.3968 14.4685 14.7369 13.7985 16 12.9998M12 14.9998V19.9998C12 19.9998 15.03 19.4498 16 17.9998C17.08 16.3798 16 12.9998 16 12.9998M9 11.9998C9.53214 10.6192 10.2022 9.29582 11 8.04976C12.1652 6.18675 13.7876 4.65281 15.713 3.59385C17.6384 2.53489 19.8027 1.98613 22 1.99976C22 4.71976 21.22 9.49976 16 12.9998M9 11.9998H4C4 11.9998 4.55 8.96976 6 7.99976C7.62 6.91976 11 7.99976 11 7.99976M4.5 16.4998C3 17.7598 2.5 21.4998 2.5 21.4998C2.5 21.4998 6.24 20.9998 7.5 19.4998C8.21 18.6598 8.2 17.3698 7.41 16.5898C7.02131 16.2188 6.50929 16.0044 5.97223 15.9878C5.43516 15.9712 4.91088 16.1535 4.5 16.4998Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                        </div>

                        <div className="pl-3 max-w-[510px] ">
                            <h3 className={` text-left font-semibold  ${ stt ? "text-white" : "text-gray-800"}`}>Mission</h3>
                            <p className={ `text-left opacity-70 font-normal ${ stt ? "text-white" : "text-gray-800"}`}>Our mission is to empower cybersecurity companies with data-driven pricing insights and guide customers to the best-fit solutions at competitive prices, ensuring value and protection for all stakeholders.
</p>
                        </div>

                    </div>

                    {/* end item  */}

                    



                </div>
                <img className={`w-[410px] transition-all duration-700 ${ stt ? " opacity-5" : " opacity-100"}` } src="/img/vision.png"></img>
            </div>
        </div>
    )
}

export default Vision;