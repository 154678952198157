const SubWho = ({defCloser, cases}) => {
    return(
        <div>
            <div className="shadow-2xl w-full rounded-3xl flex justify-start p-10 relative max-md:pt-20">
              <div className="absolute max-md:left-7 md:right-7 top-7">
                <svg onClick={() => defCloser()} className="bg-gray-100 rounded-lg p-1 cursor-pointer" width="25px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className="flex flex-col justify-center text-left gap-4">
                <h3 className="text-gray-700 text-left text-2xl font-bold">
                  <span className="text-primary">“</span> {cases.titleSub} <span className="text-primary">“</span>
                </h3>
                <p className="max-w-md">
                {cases.descriptionSub}
                </p>
                <div className="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 shadow-sm rounded-3xl">
                  <ul className="list-disc">
                    <li className="pb-2 transition-all duration-700">{cases.item1Sub}</li>
                    <li className="pb-2 transition-all duration-700">{cases.item2Sub}</li>
                    <li className="pb-2 transition-all duration-700">{cases.item3Sub}</li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    )
}

export default SubWho;