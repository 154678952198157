import React, { useEffect, useState } from 'react';

const Benefits = ({st}) => {
    const [stt, setstt] = useState();



    useEffect(() => {
        if(st == "dark") {
            setstt(true)
            console.log(stt)
    } else if (st == "light"){
        setstt(false)



    }
    }, [st]);
    return (
        <div className="flex flex-col items-center justify-center mt-[280px] ">


            {/* <div className="text-white bg-primary p-3 text-xs shadow  rounded-2xl">THE GAINS</div> */}

            <h2 className={` mb-16 text-6xl max-w-[750px] mt-5  leading-normal ${ stt ? "text-white" : "text-gray-800"}`}>Benefits of Value Aligners</h2>


            <div className="flex justify-between items-center container flex-col-reverse md:flex-row-reverse ">

                <div>
                    <img className="w-[500px]" src="/img/roc.svg"></img>
                </div>

                <div className="flex flex-col gap-7">

                {/* start  */}

                <div className="shadow flex flex-row px-10 py-8 rounded-3xl items-center gap-10 ">
                        <div >
                            <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M21 7L15.5657 12.4343C15.3677 12.6323 15.2687 12.7313 15.1545 12.7684C15.0541 12.8011 14.9459 12.8011 14.8455 12.7684C14.7313 12.7313 14.6323 12.6323 14.4343 12.4343L12.5657 10.5657C12.3677 10.3677 12.2687 10.2687 12.1545 10.2316C12.0541 10.1989 11.9459 10.1989 11.8455 10.2316C11.7313 10.2687 11.6323 10.3677 11.4343 10.5657L7 15M21 7H17M21 7V11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className=" text-gray-800 text-lg text-left"><span className="!text-xl font-semibold !text-primary">Increase Revenue</span> Optimize pricing to capture maximum value from your offerings</div>
                </div>

                {/* end  */}


                {/* start  */}

                <div className="shadow flex flex-row px-10 py-8 rounded-3xl items-center gap-10 ">
                        <div >


                            <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.13515 11.189L3.3304 4.38052C2.89291 3.59765 2.67417 3.20621 2.71103 2.88573C2.7432 2.60611 2.8917 2.353 3.1201 2.18852C3.38188 2 3.83029 2 4.72711 2H6.96193C7.29523 2 7.46187 2 7.61135 2.04813C7.74362 2.09073 7.86556 2.16042 7.96939 2.25276C8.08674 2.35712 8.17132 2.5007 8.3405 2.78788L12.0001 9L15.6597 2.78788C15.8289 2.5007 15.9135 2.35712 16.0308 2.25276C16.1347 2.16042 16.2566 2.09073 16.3889 2.04813C16.5383 2 16.705 2 17.0383 2H19.2731C20.1699 2 20.6183 2 20.8801 2.18852C21.1085 2.353 21.257 2.60611 21.2892 2.88573C21.326 3.20621 21.1073 3.59765 20.6698 4.38052L16.8651 11.189M10.5001 14L12.0001 13V18M10.7501 18H13.2501M16.5963 10.9038C19.1347 13.4422 19.1347 17.5578 16.5963 20.0962C14.0579 22.6346 9.94232 22.6346 7.40391 20.0962C4.8655 17.5578 4.8655 13.4422 7.40391 10.9038C9.94231 8.3654 14.0579 8.3654 16.5963 10.9038Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className=" text-gray-800 text-lg text-left"><span className="!text-xl font-semibold !text-primary">Enhance Competitiveness</span> Stay ahead with real-time market insights and dynamic pricing</div>
                </div>

                {/* end  */}

                {/* start  */}

                <div className="shadow flex flex-row px-10 py-8 rounded-3xl items-center gap-10 ">
                        <div >
                            <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 12L7.72711 8.43926C7.09226 7.91022 6.77484 7.6457 6.54664 7.32144C6.34444 7.03413 6.19429 6.71354 6.10301 6.37428C6 5.99139 6 5.57819 6 4.7518V2M12 12L16.2729 8.43926C16.9077 7.91022 17.2252 7.6457 17.4534 7.32144C17.6556 7.03413 17.8057 6.71354 17.897 6.37428C18 5.99139 18 5.57819 18 4.7518V2M12 12L7.72711 15.5607C7.09226 16.0898 6.77484 16.3543 6.54664 16.6786C6.34444 16.9659 6.19429 17.2865 6.10301 17.6257C6 18.0086 6 18.4218 6 19.2482V22M12 12L16.2729 15.5607C16.9077 16.0898 17.2252 16.3543 17.4534 16.6786C17.6556 16.9659 17.8057 17.2865 17.897 17.6257C18 18.0086 18 18.4218 18 19.2482V22M4 2H20M4 22H20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className=" text-gray-800 text-lg text-left"><span className="!text-xl font-semibold !text-primary">Save Time </span> Automate pricing analysis and decision-making processes</div>
                </div>

                {/* end  */}

                {/* start  */}

                <div className="shadow flex flex-row px-10 py-8 rounded-3xl items-center gap-10 ">
                        <div >
                            <svg className=" shadow-xl rounded-2xl p-3" width="52px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 10H2M22 11V8.2C22 7.0799 22 6.51984 21.782 6.09202C21.5903 5.7157 21.2843 5.40974 20.908 5.21799C20.4802 5 19.9201 5 18.8 5H5.2C4.0799 5 3.51984 5 3.09202 5.21799C2.7157 5.40973 2.40973 5.71569 2.21799 6.09202C2 6.51984 2 7.0799 2 8.2V15.8C2 16.9201 2 17.4802 2.21799 17.908C2.40973 18.2843 2.71569 18.5903 3.09202 18.782C3.51984 19 4.07989 19 5.2 19H11.5M18 21C18 21 21 19.5701 21 17.4252V14.9229L18.8124 14.1412C18.2868 13.9529 17.712 13.9529 17.1864 14.1412L15 14.9229V17.4252C15 19.5701 18 21 18 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className=" text-gray-800 text-lg text-left"><span className="!text-xl font-semibold !text-primary">Reduce Risk</span> Make data-driven pricing decisions to minimize financial risks</div>
                </div>

                {/* end  */}
                </div>

            </div>




            



        </div>
    )
}

export default Benefits;